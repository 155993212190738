import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectIsTranslating } from './selectors';
import { setTranslatingItem } from './actions';
import styles from './styles.css';

class Translation extends PureComponent {
  static propTypes = {};

  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();

    const { setTranslatingItem, namespace, t, type } = this.props;

    setTranslatingItem({
      namespace,
      t,
      type,
    });
  };

  render() {
    const { type, namespace, t, translation } = this.props;
    const extraProps = {
      className: styles.translatable,
      onClick: this.handleClick,
    };

    if (type === 'plain') {
      return <span {...extraProps}>{translation}</span>;
    }

    if (type === 'multiline') {
      return (
        <span
          {...extraProps}
          dangerouslySetInnerHTML={{ __html: translation.replace(new RegExp('\r?\n', 'g'), '<br />') }}
        />
      );
    }

    if (type === 'html') {
      return <span {...extraProps} dangerouslySetInnerHTML={{ __html: translation }} />;
    }

    return null;
  }
}

Translation.propTypes = {
  namespace: PropTypes.string.isRequired,
  t: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Translation.defaultProps = {
  type: 'plain',
};

const mapStateToProps = createStructuredSelector({
  isTranslating: selectIsTranslating,
});

const mapDispatchToprops = {
  setTranslatingItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(Translation);

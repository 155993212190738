import { createSelector } from 'reselect';

export const selectLocalState = state => state.translations;

export const selectIsTranslating = createSelector(
  [selectLocalState],
  state => state.isTranslating,
);

export const selectTranslatingItem = createSelector(
  [selectLocalState],
  state => state.translatingItem,
);

import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Head from 'next/head';
import cx from 'classnames';
import flow from 'lodash.flow';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { i18n, withTranslation } from '../i18n';
import withTranslations from '../services/Translations/withTranslations';
import Translation from '../services/Translations/Translation';
import { selectIsLoggedIn } from '../services/Auth/selectors';
import HeroLayout from '../layouts/Hero';
import HomepageSlideshow from '../components/HomepageSlideshow';
import Link from '../components/Navigation/Link';
import Button from '../components/UI/Button';
import styles from './styles.css';
import PageTitle from '../components/UI/PageTitle';
import API from '../services/API';

const amountOfRows = 4;
const amountPerRow = 6;

class Home extends PureComponent {
  static async getInitialProps(ctx) {
    const fetchRandomProfiles = API.fetch(ctx, '/user/random-profiles', { amount: amountOfRows * amountPerRow });

    return {
      namespacesRequired: ['common'],
      randomServicePicture: Math.floor(Math.random() * 3) + 1,
    };
  }

  render() {
    const { t, translate, randomServicePicture, isLoggedIn, i18n } = this.props;

    const crewResponsable =
      i18n.language === 'fr'
        ? {
            name: 'Nicolas Devroye ',
            img: 'nicolas_devroye',
            email: 'nicolasdevroye@travelbase.eu',
          }
        : {
            name: 'Jan',
            img: 'jan',
            email: 'jan@routedusoleil.org',
          };

    const brands =
      i18n.language === 'fr'
        ? [
            {
              name: 'Sportsdhiver',
              url: 'http://sportsdhiver.com',
              logo: 'logo_sportsdhiver.png',
            },
            {
              name: 'Proride',
              url: 'http://proride.be',
              logo: 'logo_proride.svg',
            },
            {
              name: 'Travelbase',
              url: 'http://travelbase.eu',
              logo: 'logo_travelbase.svg',
            },
          ]
        : [
            {
              name: 'Snowbite',
              url: 'http://snowbite.be',
              logo: 'logo_snowbite.png',
            },
            {
              name: 'Route du Soleil',
              url: 'http://routedusoleil.org',
              logo: 'logo_rds.svg',
            },
            {
              name: 'Travelbase',
              url: 'http://travelbase.eu',
              logo: 'logo_travelbase.svg',
            },
          ];

    return (
      <HeroLayout>
        <Head>
          <title>
            {t('siteTitle')} | {t('home')}
          </title>
        </Head>
        <div className={styles.banner}>
          <HomepageSlideshow language={i18n.language} />
          <Container className={styles.inner}>
            <h1>
              <Translation namespace="home" t="introTitle" />
            </h1>
            <div className={styles.brands}>
              {brands.map(brand => {
                return (
                  <a
                    key={brand.name}
                    href={brand.url}
                    title={brand.name}
                    style={{ backgroundImage: `url("/static/img/brands/${brand.logo}` }}
                  >
                    {brand.name}
                  </a>
                );
              })}
            </div>
            <h4 className="mt-4">
              <Translation namespace="home" t="introText" type="multiline" />
            </h4>
            {isLoggedIn && (
              <Link href="/profile">
                <Button className="mt-5" level="white" element={'a'}>
                  {t('myProfile')}
                </Button>
              </Link>
            )}
            {!isLoggedIn && (
              <div className="mt-5">
                <Link href="/join">
                  <Button className="mt-2" level="primary" element={'a'}>
                    <Translation namespace="home" t="joinNow" />
                  </Button>
                </Link>
                <Link href="/login">
                  <Button className="ml-2 mt-2" level="white" element={'a'}>
                    {t('login')}
                  </Button>
                </Link>
              </div>
            )}
          </Container>
        </div>
        <div className="mt-5">
          <Container>
            <PageTitle as="h2" alignment="left">
              <Translation namespace="home" t="block1Title" />
            </PageTitle>
            <Row>
              <Col xs={12} md={6} className="mt-4">
                <img
                  className="img-fluid"
                  src="/static/img/home-image.jpg"
                  alt="Service du Soleil - Photo from project The Village"
                />
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <Translation namespace="home" t="block1Text" type="html" />
              </Col>
            </Row>
            <PageTitle as="h2" alignment="right" className="mt-5">
              <Translation namespace="home" t="block2Title" />
            </PageTitle>
            <Row>
              <Col xs={12} md={6} className={styles.crewResponsable}>
                <Translation namespace="home" t="block2Text" type="html" />
                <Row className="mt-4">
                  <Col xs={3}>
                    <div className={styles.roundedImage}>
                      <img
                        className={styles.colImage}
                        src={`/static/img/team/${crewResponsable.img}.jpg`}
                        alt={crewResponsable.name}
                      />
                    </div>
                  </Col>
                  <Col xs={9}>
                    <h5>{crewResponsable.name}</h5>
                    <h6>{t('crewResponsable')}</h6>
                    <a href={`mailto:${crewResponsable.email}`}>{crewResponsable.email}</a>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className={cx('mt-4', styles.randomProfiles)}>
                <img src={`/static/img/service_${randomServicePicture}.jpg`} />
              </Col>
            </Row>
          </Container>
        </div>
      </HeroLayout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsLoggedIn,
});

export default flow(
  connect(mapStateToProps),
  withTranslations(['home']),
  withTranslation('common'),
)(Home);

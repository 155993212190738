import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { TranslationsContext } from './withTranslations';
import { selectIsTranslating } from './selectors';
import Translatable from './Translatable';

class Translation extends PureComponent {
  static propTypes = {};

  render() {
    return (
      <TranslationsContext.Consumer>
        {context => {
          const { type, namespace, t, isTranslating } = this.props;
          const translation = context.translate(namespace, t);

          if (isTranslating) {
            return <Translatable translation={translation} {...this.props} />;
          }

          if (type === 'plain') {
            return <>{translation}</>;
          }

          if (type === 'multiline') {
            return (
              <span dangerouslySetInnerHTML={{ __html: translation.replace(new RegExp('\r?\n', 'g'), '<br />') }} />
            );
          }

          if (type === 'html') {
            return <span dangerouslySetInnerHTML={{ __html: translation }} />;
          }

          return null;
        }}
      </TranslationsContext.Consumer>
    );
  }
}

Translation.propTypes = {
  namespace: PropTypes.string.isRequired,
  t: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Translation.defaultProps = {
  type: 'plain',
};

const mapStateToProps = createStructuredSelector({
  isTranslating: selectIsTranslating,
});

export default connect(mapStateToProps)(Translation);

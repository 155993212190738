import { PureComponent } from 'react';
import Head from 'next/head';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Translator from '../services/Translations/Translator';

class Base extends PureComponent {
  render() {
    const { children } = this.props;

    return (
      <div>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta charSet="utf-8" />
          <link rel="stylesheet" href="/static/css/bootstrap.min.css" />
          {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" /> */}

          <link rel="icon" type="image/png" sizes="32x32" href="/static/favicons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/static/favicons/favicon-16x16.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/static/favicons/apple-touch-icon.png" />
          <link rel="manifest" href="/static/favicons/site.webmanifest" />
          <link rel="mask-icon" href="/static/favicons/safari-pinned-tab.svg" color="#f0c52c" />
          <meta name="msapplication-TileColor" content="#f0c52c" />
          <meta name="theme-color" content="#f0c52c" />

          {process.env.NODE_ENV !== 'development' && (
            <script
              type="text/javascript"
              src="https://polyfill.io/v3/polyfill.min.js?flags=gated&features=all"
            ></script>
          )}
          <script type="text/javascript" src="/static/js/openFB/openfb.js"></script>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              openFB.init({
                appId: 121089181304841,
                oauthRedirectURL: location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "")  + "/static/js/openFB/oauthcallback.html"
              });
            `,
            }}
          ></script>
        </Head>
        <style jsx global>
          {`
            @font-face {
              font-family: Bebas Neue;
              src: url(/static/fonts/bebasneue-regular.woff) format('woff');
              font-weight: 400;
            }

            body {
              font-family: Helvetica, sans-serif;
            }

            html,
            body,
            body > div:first-child,
            div#__next,
            div#__next > div,
            div#__next > div > div {
              height: 100%;
            }

            .inner-body {
              display: flex;
              flex-direction: column;
            }

            .inner-body .main {
              flex: 1;
            }

            .h1,
            .h2,
            .h3,
            .h4,
            .h5,
            .h6,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              line-height: 1.4;
            }

            .form-control::placeholder {
              color: #c0c0c4;
              font-style: italic;
            }

            .navbar-collapse.collapsing {
              position: fixed;
              height: 100%;
              overflow: auto;
              transition: none;
            }

            .navbar-light .navbar-toggler-icon {
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
            }
          `}
        </style>
        <div className="inner-body">
          <Navigation />
          <div className="main">{children}</div>
          <Footer />
          <Translator />
        </div>
      </div>
    );
  }
}

export default Base;

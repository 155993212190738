import React from 'react';
import cx from 'classnames';

import styles from './styles.css';

const slides = ['summer', 'journeyofsound', 'budapestrally', 'canoetrip', 'snowbreak'];
const slidesNL = ['summer', 'journeyofsound', 'budapestrally', 'canoetrip', 'snowbreak'];
const slidesFR = ['summer', 'budapestrally', 'canoetrip', 'sportsdhiver', 'proride'];

class HomepageSlideshow extends React.Component {
  constructor(props) {
    super(props);

    const languageSlides = props.language === 'fr' ? slidesFR : slidesNL;
    this.state = {
      slides: languageSlides.map((slide, index) => ({
        url: `/static/img/homepage-banners/${slide}.jpg`,
        loaded: index === 0,
      })),
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.state.slides.map((slide, index) => {
      const img = new Image();
      img.src = slide.url;
      img.onload = () => {
        this.setState(prevState => {
          const slides = [...prevState.slides];
          return {
            ...prevState,
            slides: slides.map((slide, i) => ({
              ...slide,
              loaded: i === index ? true : slide.loaded,
            })),
          };
        });
      };
    });

    this.interval = window.setInterval(() => {
      const nextSlideNumber =
        this.state.currentSlide === this.state.slides.length - 1 ? 0 : this.state.currentSlide + 1;

      this.setState({
        currentSlide: nextSlideNumber,
      });
    }, 2500);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <>
        {this.state.slides.map((slide, index) => {
          if (!slide.loaded) {
            return null;
          }

          const style = {
            background: `linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.4)), url(${slide.url}) no-repeat center center`,
            backgroundSize: 'cover',
            opacity: this.state.currentSlide === index ? 1 : 0,
          };
          return <div key={slide.url} className={cx(styles.slide)} style={style}></div>;
        })}
      </>
    );
  }
}

export default HomepageSlideshow;

import React, { PureComponent } from 'react';
import { Container, Button } from 'react-bootstrap';
import flow from 'lodash.flow';
import throttle from 'lodash.throttle';
import { connect } from 'react-redux';
import { FaBars, FaTimes } from 'react-icons/fa';
import { createStructuredSelector } from 'reselect';
import cx from 'classnames';

import NavigationItems from './NavigationItems';
import { withTranslation } from '../../i18n';
import AuthService from '../../services/Auth';
import { routerPush } from '../../utils/router';
import { selectIsLoggedIn } from '../../services/Auth/selectors';
import { logOut } from '../../services/Auth/actions';
import Link from './Link';
import styles from './styles.css';

class Navigation extends PureComponent {
  state = {
    isExpanded: false,
  };

  constructor() {
    super(...arguments);
    this.handleResize = throttle(this.handleResize, 100);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = event => {
    if (this.state.isExpanded && window.outerWidth > 768) {
      this.handleToggle();
    }
  };

  handleLogout = () => {
    this.props.logOut();
    AuthService.removeTokenFromStorage();
    routerPush('');
  };

  handleToggle = event => {
    this.state.isExpanded ? this.enableScrolling() : this.disableScrolling();

    this.setState(state => ({
      isExpanded: !state.isExpanded,
    }));

    if (event && event.currentTarget.blur) {
      event.currentTarget.blur();
    }
  };

  disableScrolling = () => {
    document.querySelector('.inner-body').style.overflowX = 'hidden';
  };

  enableScrolling = () => {
    document.querySelector('.inner-body').style.overflowX = 'visible';
  };

  render() {
    const { t, isLoggedIn } = this.props;
    const { isExpanded } = this.state;

    return (
      <Container fluid>
        <div className={cx(styles.navigation, { [styles.isExpanded]: isExpanded })}>
          <Link href="/">
            <a className={styles.logo} title={t('siteTitle')}>
              <img src="/static/img/logo.png" alt={t('siteTitle')} />
            </a>
          </Link>
          <button className={styles.toggleButton} onClick={this.handleToggle}>
            <FaBars />
          </button>
          <div className={styles.navContainer}>
            <nav className={styles.nav}>
              <NavigationItems handleLogout={this.handleLogout} isLoggedIn={isLoggedIn} t={t} />
            </nav>
            <button className={styles.closeButton} onClick={this.handleToggle}>
              <FaTimes />
            </button>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsLoggedIn,
});

const mapDispatchToProps = {
  logOut,
};

export default flow(
  withTranslation('common'),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Navigation);

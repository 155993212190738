import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaFacebookMessenger, FaInstagram, FaRegEnvelope } from 'react-icons/fa';

import { withTranslation } from '../../i18n';
import styles from './styles.css';

class Footer extends PureComponent {
  render() {
    const { t, i18n } = this.props;
    const instagram = i18n.language === 'fr' ? 'leservicedusoleil' : 'servicedusoleil';

    return (
      <div className={styles.footer}>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <h3>{t('footerFollowUs')}</h3>
              <a
                href={`https://instagram.com/routedusoleil`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footerLink}
              >
                <FaInstagram className={styles.linkIcon} /> routedusoleil
              </a>
              <a
                href={`https://instagram.com/${instagram}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footerLink}
              >
                <FaInstagram className={styles.linkIcon} /> {instagram}
              </a>
            </Col>
            <Col xs={12} md={4}>
              <h3>{t('footerQuestions')}</h3>
              <a
                href="https://m.me/routedusoleil"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footerLink}
              >
                <FaFacebookMessenger className={styles.linkIcon} /> {t('footerSendUsAMessage')}
              </a>
              <a href="mailto:info@servicedusoleil.org" target="_blank" className={styles.footerLink}>
                <FaRegEnvelope className={styles.linkIcon} /> info@servicedusoleil.org
              </a>
            </Col>
            <Col xs={12} md={4}>
              <h3>{t('footerAboutUs')}</h3>
              <p>
                {t('footerServiceIsPartOf')}
                <ul className="pl-0">
                  {i18n.language === 'fr' && (
                    <>
                      <li className="list-unstyled">
                        <a href="http://proride.be" className={styles.borderedLink} target="_blank">
                          Proride
                        </a>
                      </li>
                      <li className="list-unstyled">
                        <a href="http://sportsdhiver.com" className={styles.borderedLink} target="_blank">
                          Sportsdhiver.com
                        </a>
                      </li>
                    </>
                  )}
                  {i18n.language !== 'fr' && (
                    <>
                      <li className="list-unstyled">
                        <a href="http://snowbite.be" className={styles.borderedLink} target="_blank">
                          Snowbite.be
                        </a>
                      </li>
                      <li className="list-unstyled">
                        <a href="http://routedusoleil.org" className={styles.borderedLink} target="_blank">
                          Routedusoleil.org
                        </a>
                      </li>
                    </>
                  )}

                  <li className="list-unstyled">
                    <a href="http://travelbase.eu" className={styles.borderedLink} target="_blank">
                      Travelbase.eu
                    </a>
                  </li>
                </ul>
              </p>
              <p>© Route du Soleil. {t('footerAllRightsReserved')}.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Footer);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectIsTranslating, selectTranslatingItem } from './selectors';
import TranslatorModal from './TranslatorModal';

class Translator extends PureComponent {
  render() {
    if (!this.props.isTranslating) {
      return null;
    }

    if (!this.props.item) {
      return null;
    }

    return <TranslatorModal item={this.props.item} />;
  }
}

const mapStateToProps = createStructuredSelector({
  isTranslating: selectIsTranslating,
  item: selectTranslatingItem,
});

export default connect(mapStateToProps)(Translator);

import React, { Component } from 'react';
import get from 'lodash.get';

import API from '../API';
import { i18n } from '../../i18n';

export const TranslationsContext = React.createContext({ translations: {} });

const getDisplayName = Component => Component.displayName || Component.name || 'ComponentWithTranslations';

export const withTranslations = namespaces => WrappedComponent =>
  class extends Component {
    static displayName = `withTranslations(${getDisplayName(WrappedComponent)})`;

    static async getInitialProps(ctx) {
      const currentLanguage = get(ctx, 'req.language', i18n.language);
      const fetchTranslations = API.fetch(ctx, '/text', {
        namespaces: [...namespaces, 'global'],
        language: currentLanguage || i18n.options.defaultLanguage,
      });

      const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
      const translations = await fetchTranslations;

      return { ...componentProps, translations };
    }

    translate = (namespace, key) => {
      return get(this.props.translations, [namespace, key], `${namespace}.${key}`);
    };

    render() {
      const { translations, ...rest } = this.props;

      return (
        <TranslationsContext.Provider value={{ translate: this.translate }}>
          <WrappedComponent translate={this.translate} {...this.props} />
        </TranslationsContext.Provider>
      );
    }
  };

export default withTranslations;

import React, { PureComponent } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';

import NavLink from './NavLink';
import styles from './styles.css';
import TranslatorButton from './TranslatorButton';

class NavigationItems extends PureComponent {
  render() {
    const { isLoggedIn, handleLogout, t } = this.props;

    return (
      <>
        <NavLink href="/" activeIfMatchesExactly className={styles.navItem}>
          <Nav.Link>
            <span>{t('home')}</span>
          </Nav.Link>
        </NavLink>
        {/* <NavLink href="/trips" className={styles.navItem}>
                <Nav.Link>{t('trips')}</Nav.Link>
              </NavLink> */}
        {!isLoggedIn && (
          <>
            <NavLink href="/join" className={styles.navItem}>
              <Nav.Link>
                <span>{t('join')}</span>
              </Nav.Link>
            </NavLink>
            <NavLink href="/login" className={styles.navItem}>
              <Nav.Link>
                <span>{t('login')}</span>
              </Nav.Link>
            </NavLink>
          </>
        )}
        {isLoggedIn && (
          <>
            <NavLink href="/profile" className={styles.navItem}>
              <Nav.Link>
                <span>{t('myProfile')}</span>
              </Nav.Link>
            </NavLink>
            <Nav.Link onClick={handleLogout} className={styles.navItem}>
              <span>{t('logOut')}</span>
            </Nav.Link>
            <TranslatorButton />
          </>
        )}
        <NavDropdown title={<FaGlobe />} className={styles.languageDropdown}>
          <NavDropdown.Item href="/nl">NL</NavDropdown.Item>
          <NavDropdown.Item href="/fr">FR</NavDropdown.Item>
        </NavDropdown>
      </>
    );
  }
}

export default NavigationItems;

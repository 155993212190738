import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import Router from 'next/router';
import dynamic from 'next/dynamic';

import Button from '../../components/UI/Button';
import { selectTranslatingItem } from './selectors';
import { setTranslatingItem } from './actions';
import Input from '../../components/Forms/Input';
import Editor from '../../components/Forms/Editor';
import API from '../API';
import { routerPush } from '../../utils/router';

const languages = ['nl', 'fr'];

class TranslatorModal extends PureComponent {
  state = {
    translations: null,
    isSubmitting: false,
  };

  async componentDidMount() {
    const { item, setTranslatingItem } = this.props;

    try {
      const translations = await API.fetch(undefined, '/text/item', {
        namespace: item.namespace,
        key: item.t,
      });

      if (Object.keys(translations).length === 0) {
        setTranslatingItem(null);
        alert('unexisting translation');
        return;
      }

      this.setState({ translations });
    } catch (error) {
      setTranslatingItem(null);
    }
  }

  handleHideModal = () => {
    this.props.setTranslatingItem(null);
  };

  getInitialValues() {
    const values = {};

    languages.map(language => {
      if (this.state.translations[language]) {
        values[language] = this.state.translations[language].value;
      }
    });

    return values;
  }

  handleSubmit = async values => {
    const { item, setTranslatingItem } = this.props;

    if (Object.keys(values).length === 0) {
      setTranslatingItem(null);
      return;
    }

    this.setState({ isSubmitting: true });

    try {
      await API.fetch(undefined, '/text/item/update', {
        namespace: item.namespace,
        key: item.t,
        values,
      });
    } catch (error) {}

    setTranslatingItem(null);
    Router.replace(window.location.href);
  };

  render() {
    const { item } = this.props;
    const { translations } = this.state;

    const type = translations && translations[Object.keys(translations)[0]].type;

    return (
      <Modal show={true} onHide={this.handleHideModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Translate {item.namespace}.{item.t}
          </Modal.Title>
        </Modal.Header>

        {translations && (
          <div>
            <Form
              onSubmit={this.handleSubmit}
              initialValues={this.getInitialValues()}
              render={({ handleSubmit, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Field component="input" type="hidden" value={type} name="type" />
                    {languages.map(language => (
                      <Container key={language}>
                        <Row>
                          <Col>
                            {type !== 'html' && (
                              <Field
                                component={Input}
                                name={language}
                                as={type === 'multiline' ? 'textarea' : undefined}
                                rows={type === 'multiline' ? 4 : undefined}
                                label={language}
                              />
                            )}
                            {type === 'html' && (
                              <div className="mt-4">
                                <Field component={Editor} name={language} label={language} />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type="button" level="secondary" onClick={this.handleHideModal}>
                      Close
                    </Button>
                    <Button type="submit" level="primary">
                      Save
                    </Button>
                  </Modal.Footer>
                </form>
              )}
            />
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToprops = {
  setTranslatingItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(TranslatorModal);

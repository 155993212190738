import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.css';

class PageTitle extends PureComponent {
  render() {
    const { children, alignment, className, as } = this.props;
    const ElementToUse = as;

    return (
      <ElementToUse className={cx(styles.pageTitle, styles[alignment], className)}>
        <span className={styles.inner}>{children}</span>
      </ElementToUse>
    );
  }
}

PageTitle.propTypes = {
  children: PropTypes.any.isRequired,
  as: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['center', 'left', 'right']).isRequired,
  className: PropTypes.string,
};

PageTitle.defaultProps = {
  as: 'h1',
  alignment: 'center',
};

export default PageTitle;

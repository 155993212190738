import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import dynamic from 'next/dynamic';

import styles from './Input.css';

const CKEditor = dynamic(() => import('../CKEditor'), {
  ssr: false,
});

class Editor extends PureComponent {
  editor = null;

  handleInit = editor => {
    this.editor = editor;
  };

  handleChange = (a, b, c, d, e, f) => {
    this.props.input.onChange(this.editor.getData());
  };

  render() {
    const { label, helpText, required, input, meta, ...rest } = this.props;
    const Wrapper = label ? 'div' : React.Fragment;

    return (
      <Wrapper style={{ width: '100%' }} {...(label ? { className: styles.label } : {})}>
        {label && (
          <>
            {label}
            {helpText && <span className={styles.helpText}>{helpText}</span>}
            {required && <Required />}
          </>
        )}
        <CKEditor
          name={input.name}
          data={input.value}
          {...rest}
          onInit={this.handleInit}
          onChange={this.handleChange}
        />
        {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
      </Wrapper>
    );
  }
}

Editor.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Editor;

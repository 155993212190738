import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Nav, NavDropdown } from 'react-bootstrap';

import AuthService from '../../services/Auth';
import { selectIsLoggedIn } from '../../services/Auth/selectors';
import { toggleIsTranslating } from '../../services/Translations/actions';
import styles from './styles.css';

class TranslatorButton extends PureComponent {
  state = {
    canTranslate: false,
  };

  handleStartTranslating = () => {
    this.props.toggleIsTranslating();
  };

  async componentDidMount() {
    try {
      const user = await AuthService.fetchUserData();
      if (user && user.isAdmin) {
        this.setState({ canTranslate: true });
      }
    } catch (error) {}
  }

  render() {
    if (!this.state.canTranslate) {
      return null;
    }

    return (
      <Nav.Link onClick={this.handleStartTranslating} className={styles.navItem}>
        <span>Edit text</span>
      </Nav.Link>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsLoggedIn,
});

const mapDispatchToProps = {
  toggleIsTranslating,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TranslatorButton);

import { PureComponent } from 'react';

import Base from './Base';

class Hero extends PureComponent {
  render() {
    const { children } = this.props;

    return <Base>{children}</Base>;
  }
}

export default Hero;
